<template>
  <div class="home-page">
    <container-elem class="is-fullwidth hero-teaser">
      <image-elem
        :file="$store.state.page.content.pageimage"
        size="hero" 
        class="is-hero"/>
      <div class="headline">
        <h1><span>{{ $store.state.page.content.headline }}</span></h1>
        <h2><span>{{ $store.state.page.content.subheadline }}</span></h2>
      </div>
    </container-elem>
    <container-elem class="content">
      <row-elem>
        <col-elem class="is-5 is-offset-1 wizard">
          <link-elem :link="{ name: 'wizard' }">
            <div class="typo tile">
              <svg-elem name="sprite-wizard"></svg-elem>
              <h2>{{ $store.state.page.content.wizardheadline }}</h2>
              <text-elem :content="$store.state.page.content.wizardtext" class="is-tool"></text-elem>
            </div>
          </link-elem>
        </col-elem>
        <col-elem class="is-5 configurator">
          <link-elem :link="{ name: 'configurator' }">
            <div class="typo tile">
              <svg-elem name="sprite-configurator"></svg-elem>
              <h2>{{ $store.state.page.content.configuratorheadline }}</h2>
              <text-elem :content="$store.state.page.content.configuratortext" class="is-tool"></text-elem>
            </div>
          </link-elem>
        </col-elem>
      </row-elem>
      <row-elem class="typo is-teaser">
        <col-elem class="is-8 is-offset-1">
          <text-elem :content="$store.state.page.content.copy"></text-elem>
        </col-elem>
      </row-elem>
    </container-elem>
  </div>
</template>

<script>
export default {
  name: 'home-page'
}
</script>

<style lang="sass">
.home-page
  background-color: $primary-color
  .hero-teaser
    position: relative
    height: m(80)
    z-index: $z-index-base
    .headline
      position: absolute
      display: flex
      flex-direction: column
      top: 10%
      h1
        span
          padding: 5px m(4) 5px m(8)
          +font('jumbo')
          color: white()
          background-color: $primary-color
      h2
        margin-top: m(2)
        span
          padding: 3px m(3) 3px m(8)
          +font('medium')
          color: white()
          background-color: $primary-color
  .content
    position: relative
    margin-top: - m(20)
    z-index: $z-index-base + 1
    .tile
      height: 100%
      padding: m(4)
      background-color: white()
      text-align: center
      .svg-elem
        width: 40%
        fill: $primary-color
      h2
        margin-bottom: m(3)
        +font('regular')
        color: $primary-color
    .is-teaser
      padding: m(3) 0 m(10) 0
      *
        color: white()

+md
  .home-page
    .hero-teaser
      height: m(70)
      .headline
        h1
          span
            padding-left: content-padding('md')
            padding-right: m(2)
        h2
          span
            padding-left: content-padding('md')
            padding-right: m(2)

+sm
  .home-page
    .hero-teaser
      height: m(70)
</style>